<template>
  <div class="bg-main d-flex flex-column h-100">
    <HeaderAuth back/>
    <div class="d-flex justify-center">
      <div class="auth-card text-center">
        <h2 class="auth-card__title mb-8">Восстановление пароля</h2>
        <div v-if="resetPasswordSuccess">
          <span>
            На вашу электронную почту <b>{{email}}</b> направлена ссылка для восстановления пароля.
            <br>
            Перейдите по ссылке в письме и укажите новый пароль.
          </span>
          <br><br>
          <v-btn class="w-100" x-large color="primary"
                 @click="$router.push({name: 'home'})">
            Вернуться на главную
          </v-btn>
        </div>
        <form v-else @submit.prevent="submit">
          <v-text-field label="Адрес эл. почты *"
                        v-model="email"
                        @input="clearError"
                        @blur="$v.email.$touch()"
                        :error-messages="emailErrors"
                        outlined />

          <div v-for="(err, ind) in errors" :key="ind" class="error--text">
            <template v-if="Array.isArray(err[1]) && err[0] !== 'email'">
              <div v-for="(e, i) in err[1]" :key="i">{{e}}</div>
            </template>
          </div>

          <v-btn type="submit" class="btn-disabled-primary mt-8 w-100" x-large color="primary"
                 :loading="loading" :outlined="loading || $v.$invalid" :disabled="loading || $v.$invalid">
            Продолжить
          </v-btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import HeaderAuth from '@/components/headers/HeaderAuth';
import { MESSAGES } from '@/common/constants/errorMessages';
import { EMAIL } from '@/common/constants/validations';

export default {
  name: 'ResetPassword',
  metaInfo: {
    title: 'Восстановление пароля',
  },
  components: {
    HeaderAuth,
  },
  validations: {
    email: {
      required, email, maxLength: maxLength(EMAIL.MAX_LENGTH),
    },
  },
  data: () => ({
    resetPasswordSuccess: false,
    email: '',
  }),
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      errors: 'auth/errors',
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.email.email) errors.push(MESSAGES.INCORRECT_EMAIL);
      if (!this.$v.email.maxLength) errors.push(MESSAGES.MAX_LENGTH(EMAIL.MAX_LENGTH));

      return this.errors.find((err) => err[0] === 'email')?.[1] ? MESSAGES.EMAIL_DOES_NOT_EXIST : errors;
    },
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
      clearError: 'auth/clearError',
    }),
    onInput(field) {
      this.$v[field].$touch();
      if (this.errors.length) this.clearError();
    },
    submit() {
      if (this.loading) return;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.resetPassword(this.email).then(() => {
          this.resetPasswordSuccess = true;
        });
      }
    },
  },
  beforeDestroy() {
    this.clearError();
  },
};
</script>
